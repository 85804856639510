import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Tags from 'components/Common/Blog/Tags'
import layoutContext from 'components/layoutContext'
import SEO from 'components/seo'

const PostTags = require('constants/Tags')

const BlogPost = ({
  data: {
    markdownRemark: {
      frontmatter: {
        title,
        tags,
        lang,
        date,
        path,
        rating,
        canonicalUrl,
        image: {
          childImageSharp: {
            resize: { src }
          }
        }
      },
      html
    }
  }
}) => {
  tags = tags ? tags.filter(tag => tag !== 'popular') : []
  useContext(layoutContext).setCult(lang)

  let postTags = []

  Object.keys(PostTags).forEach(key => {
    tags &&
      tags.map(tag => {
        if (PostTags[key][lang] === tag) {
          postTags.push({ tag, link: key })
        }
      })
  })

  return (
    <>
      <SEO
        title={title}
        path={path}
        date={date}
        type='post'
        image={src}
        rating={rating ? rating : 4.6}
        lang={lang}
        canonicalUrl={canonicalUrl}
      />
      <div className='medium-container'>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <Tags tags={postTags} lang={lang} />
      </div>
    </>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        path
        tags
        lang
        date
        canonicalUrl
        rating
        image {
          childImageSharp {
            resize(width: 250, height: 250) {
              src
            }
          }
        }
      }
    }
  }
`

BlogPost.propTypes = {
  data: PropTypes.object
}

export default BlogPost
