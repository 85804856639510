import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import './Tags.scss'

const Tags = ({ tags, lang }) => (
  <div className='tags'>
    {tags.map(({ tag, link }) => (
      <Link
        key={tag}
        className='tags-link'
        to={`${lang === 'ru' ? '/ru' : ''}/blog/${link}`}
      >
        <div className='tag'>{tag}</div>
      </Link>
    ))}
  </div>
)

Tags.propTypes = {
  tags: PropTypes.array,
  lang: PropTypes.string
}

export default Tags
